<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <div class="sign-up-container">
            <div class="banner">
                <div>
                    <div class="banner-title">报名流程</div>
                    <img ondragstart="return false" src="@/assets/images/fashion/sign-up-banner-text.png">

                    <a-popover placement="bottom">
                        <template slot="content">
                            <img class="kf-qr" src="@/assets/images/wx-kf.png" alt="">
                        </template>
                        <a-button class="lr-button">立即报名</a-button>
                    </a-popover>

                </div>
            </div>

            <div class="online-container">
                <div class="online">
                    <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/online-title.png"
                         alt="">
                    <img class="content-img" ondragstart="return false" src="@/assets/images/fashion/online-content.png"
                         alt="">
                </div>
            </div>

            <div class="bm-container">
                <div class="bm">
                    <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/xcbm-title.png"
                         alt="">

                    <div class="bm-list">
                        <div class="bm-row">
                            <div>
                                <img ondragstart="return false" src="@/assets/images/fashion/bm1.png" alt="">
                                <div>与课程老师现场了解课程 <br>参观校区</div>
                            </div>
                        </div>
                        <div class="bm-row">
                            <div>
                                <img ondragstart="return false" src="@/assets/images/fashion/bm2.png" alt="">
                                <div>通过手机或电脑注册个人中心并登陆颜创官网，选择在线报名，按照提示完成报名表填写及定金缴纳，现场领取入学通知函</div>
                            </div>
                        </div>
                        <div class="bm-row">
                            <div>
                                <img ondragstart="return false" src="@/assets/images/fashion/bm3.png" alt="">
                                <div>按照入学通知函准备入学资料及其他事项 <br>
                                    非本地区的学员请根据入学通知函 <br>
                                    注明的报到、上课时间提前预定往返机票、火车票
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";

    export default {
        name: "SignUp",
        components: {LRightContact, LIcp, LFooter, MainNav},
        metaInfo: meta.appendTitle('报名流程'),
    }
</script>

<style lang="less" scoped>
    .banner {
        height: 600px;
        background-image: url("../../assets/images/fashion/sp-banner.png");
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-title {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
        letter-spacing: 1px;
        margin-bottom: 45px;
    }

    .banner img {
        width: 454px;
        margin-bottom: 50px;
        display: block;
    }

    .online-container {
        background: url("../../assets/images/fashion/online-bg.png");
    }

    .online {
        width: 1200px;
        max-width: 100%;
        margin: 0 auto;
        padding: 1px;

        .title-img {
            width: 470px;
            margin-top: 100px;
            margin-bottom: 80px;
        }

        .content-img {
            width: 100%;
            margin-bottom: 120px;
        }
    }

    .bm-container {
        padding: 1px;

        .bm {
            width: 1200px;
            max-width: 100%;
            margin: 0 auto 50px;
        }

        .title-img {
            margin-top: 100px;
            margin-bottom: 80px;
        }

        .bm-list {
            display: flex;
            justify-content: space-between;
        }

        .bm-row {
            padding: 25px;
            border: 1px solid rgba(13, 57, 46, 1);
            margin-bottom: 50px;
            width: 387px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;

            img {
                margin-bottom: 22px;
            }
        }
    }

    .kf-qr {
        width: 200px;
    }

    .lr-button {
        height: auto;
        background: #1F7762;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        border: 0;
        border-radius: 0;
        padding: 10px 28px;
    }
</style>